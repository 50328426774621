import React, { FormEvent, ChangeEvent, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountSpecificFeature, useAccountSpecificFeature } from '../../acl/features';
import { State } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { loadTicketCustomFields } from '../actions';
import ActionBar from '../../../mui-custom/ActionBar/ActionBar';
import { CustomField } from '../../../components/CustomField';
import { useMobile } from '../../../hooks/useBreakpoints';
import { api } from '../../../lib/api';
import { CasaviCustomField } from '@api/v1';
import { hideNotification, showNotification } from '../../actions';
import { DropzoneFile } from '../../../components/Dropzone/types';
import i18next from 'i18next';

const fixedCustomField = (t: i18next.TFunction) =>
	[
		{
			position: 'title',
			name: t('tickets:fixed_title_name'),
			id: 0,
			description: t('tickets:fixed_title_description'),
			fieldType: 'TEXTFIELD',
			required: true
		},
		{
			position: 'description',
			name: t('tickets:fixed_description_name'),
			id: 0,
			description: t('tickets:fixed_description_description'),
			fieldType: 'TEXTBOX',
			required: true
		},
		{
			position: 'deadlineAt',
			name: t('tickets:fixed_deadline_name'),
			id: 0,
			description: t('tickets:fixed_deadline_description'),
			fieldType: 'DATE',
			dataJSON: { withTime: true, decimalPlaces: 0 }
		}
	] as CasaviCustomField[];

export const AddCustomFieldsPage = ({ match }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const isMobile = useMobile();

	const { ticketCustomFields } = useSelector((state: State) => state.tickets);

	const ticketFields = [...fixedCustomField(t), ...ticketCustomFields];
	const { search } = useLocation();
	const [values, setValues] = useState<{ [x: string]: unknown }>({});
	const [files, setFiles] = useState<{ [x: string]: DropzoneFile[] }>({});

	useEffect(() => {
		dispatch(loadTicketCustomFields({ casaviTenantId, ticketTypeId }));
	}, [search]);

	const hasTicketsCreation = useAccountSpecificFeature(AccountSpecificFeature.TicketCreation);
	if (!hasTicketsCreation) return null;

	const queryParams = new URLSearchParams(search);
	const ids: number[] = JSON.parse(queryParams.get('ids'));
	const [propertyManagerId, propertyExternalId, ticketTypeId, casaviTenantId, casaviContactId] =
		ids ?? [];
	if (!propertyManagerId || !propertyExternalId || !ticketTypeId || !casaviTenantId) return null;

	// const uploadFiles = (files: DropzoneFile[]) => {
	// 	const isUploaded = isReadyToUpload(files);
	// 	const uploadFailed = containsFailedFiles(files);
	// };

	const setField =
		(position: number | string) =>
		({ target }: ChangeEvent<HTMLInputElement>) => {
			let tempFiles: DropzoneFile[] = [];
			let pos;
			if (target.type === 'file') {
				tempFiles = [].concat(values[position], Array.from(target.files));
				pos = tempFiles;
				setFiles((prev) => ({ ...prev, [position]: tempFiles }));
			} else if (target.type === 'checkbox') {
				pos = target.checked;
			} else {
				pos = target.value ?? null;
			}

			setValues((prev) => ({
				...prev,
				[position]: pos
			}));
		};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const positionToAttributes = {};
		ticketFields.forEach(({ id, position, name, description }) => {
			positionToAttributes[position] = { id, name, description };
		});

		const formData = new FormData();
		Object.entries(values).forEach(([_position, _value]) => {
			if (_value instanceof FileList) {
				Array.from(_value).forEach((file) => formData.append(_position, file));
			} else {
				formData.append(_position, _value as string | Blob);
			}
		});

		formData.append('TicketTypeId', String(ticketTypeId));
		formData.append('CommunityId', String(propertyExternalId));
		formData.append('TenantId', String(casaviTenantId));
		formData.append('ContactId', String(casaviContactId));
		formData.append('positionToAttributes', JSON.stringify(positionToAttributes));
		formData.append('attachments', String(files));

		try {
			const { id } = await api.post('/api/v1/tickets/create-casavi-ticket', formData);
			setTimeout(() => dispatch(hideNotification()), 5000);
			if (!id) throw new Error('Failed to submit form');

			dispatch(showNotification(t('tickets:ticket_created_message'), 'success', true));
			history.push(`/app/tickets/detail/${id}`);
		} catch (error) {
			dispatch(showNotification(t('tickets:ticket_submit_error'), 'error', true));
		}
	};

	return (
		<Stack sx={{ m: 1, py: 1 }}>
			<Typography variant="h6">{t('tickets:custom_fields_title')}</Typography>
			<br />
			<form onSubmit={handleSubmit}>
				{[...ticketFields].map((props) => (
					<CustomField key={props.position} {...props} onChange={setField(props.position)} />
				))}
				<br />
				<ActionBar
					primary={{
						children: t('general:submit'),
						type: 'submit'
					}}
					alternate={{
						children: t('general:cta_go_back'),
						href: '/app/tickets/add', // Add modal ask for confirming if want to leave/cancel/delete the temp data/ticket creation
						disabled: !isMobile
					}}
				/>
			</form>
		</Stack>
	);
};
